/**
 * @file Collection of vue router specific helper functions
 * @author Josua Todebusch
 * @version 1.0.0
 */

import Router from '@/router/index.js';

export default
{
	/**
	 * @description Pushes a new route into history. If the route is the same as the current route, a reload is executed instead.
	 * 
	 * @param {object} route VueRouter route object to go to
	 */
	navigate (route)
	{
		// if full paths are equal -> reload page
		if (this.routesEqual(Router.resolve(route).resolved, Router.currentRoute))
		{
			return Router.go(0);
		}
		
		// route to new page with new history entry
		Router.push(route);
	},
	
	/**
	 * @description Compares multiple routes by their fullPath to determine if they are the same
	 * 
	 * @param {...object} routes Any amount of VueRouter route objects
	 * 
	 * @returns {boolean}
	 */
	routesEqual (...routes)
	{
		return routes.every(route => route.fullPath && route.fullPath === routes[0].fullPath);
	}
};