/**
 * @file Collection of helper functions for structure / orderables management in the presenter framework
 * @author Josua Todebusch
 * @version 1.1.0
 */

import Store from '@/store/index.js';

export default
{
	// sets the Store values for presenter.object and presenter.parent based on indexes
	evaluateCurrentStructureLocation (indexes)
	{
		// replace all "type": "resolve" with their respective orderables
		Store.commit('presenter/set', {structure: this.resolveOrderables(Store.state.presenter.structure)});
		
		// set object as structure before start
		Store.commit('presenter/set', {object: Store.state.presenter.structure});
		
		// go down in the structure tree for every index
		for (const index of indexes || [])
		{
			// first set the new parent object
			Store.commit('presenter/set', {parent: Store.state.presenter.object});
			
			// then set the new actual object based on the parent
			Store.commit('presenter/set', {object: Store.state.presenter.parent.objects[index]});
		}
	},
	
	// replaces oderable object placeholders in the given structure with their actual object
	resolveOrderables (object)
	{
		if (object.objects)
		{
			for (const child of object.objects)
			{
				this.resolveOrderables(child);
			}
		}
		
		if (object.type === 'resolve')
		{
			Object.assign(
				object,
				Store.state.presenter.orderables.find(orderable => orderable.id == object.id)
			);
		}
		
		return object;
	}
};