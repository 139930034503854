/**
 * @file Collection of helper functions for navigation in the presenter framework
 * @author Josua Todebusch
 * @version 1.0.0
 */

import Router from '@/router/index.js';
import Store from '@/store/index.js';
import TranslationHelper from './translationhelper.js';
import VueRouterUtility from './vue_router_utility.js';

export default
{
	/**
	 * @description helper method for absolute navigation
	 * 
	 * @param {path} path path to go to
	 * @param {integer[]} indexes array of integers: path for resolving object with indexes in the structure
	 */
	navigateFixed (path, indexes)
	{
		const route =
		{
			path: path,
			query: {}
		};
		
		if (indexes !== undefined)
		{
			route.query.indexes = typeof indexes === 'string' ? indexes : JSON.stringify(indexes);
		}
		
		VueRouterUtility.navigate(route);
	},
	
	/**
	 * @description helper method for relative navigation through the structure (further down)
	 * 
	 * @param {object} object structure object to navigate to (one layer lower than current object only, used for type)
	 * @param {integer} index index of the object to navigate to in the current objects "objects" property
	 */
	navigateLower (object, index)
	{
		// not in presenter wrapper: open website in new tab
		if (object.type === 'website' && Store.state.presenter.environment !== 'Presenter')
		{
			return window.open(
				TranslationHelper.translateWithBaseObjectValueAsDefault(object, 'url'),
				'_blank'
			);
		}
		
		const route =
		{
			path: object.type,
			query:
			{
				indexes: JSON.stringify([...this.getParsedIndexesQuery(), parseInt(index)])
			}
		};
		
		VueRouterUtility.navigate(route);
	},
	
	/**
	 * @description helper method for relative navigation through the structure (back / up). This method will attempt to navigate a level higher in the structure but will use the history go back if the indexes url parameter does not exist or is empty.
	 */
	navigateHighOrBack ()
	{
		const route =
		{
			path: Store.state.presenter.parent?.type,
			query:
			{
				indexes: this.getParsedIndexesQuery()
			}
		};
		
		if (route.query.indexes.length === 0 || !Store.state.presenter.parent?.type)
		{
			Router.go(-1);
			
			return;
		}
		
		route.query.indexes.pop();
		
		route.query.indexes = JSON.stringify(route.query.indexes);
		
		VueRouterUtility.navigate(route);
	},
	
	/**
	 * @description wrapper method for getting the route query 'indexes' parsed as JSON
	 * 
	 * @returns {array}
	 */
	getParsedIndexesQuery ()
	{
		return (Router.currentRoute.query?.indexes ? JSON.parse(Router.currentRoute.query?.indexes) : []);
	}
};