/**
 * @description Vue wrapper plugin for CEF Presenter
 * 
 * @example main.js
 * import Vue from 'vue'
 * import wrapper from '@/util/wrapper'
 * 
 * Vue.use(wrapper, { 
 * 		initialized: (success, type) => 
 * 		{ 
 * 			// here we can check success and mount app 
 * 		} 
 * };
 * 
 * // later in modules
 * console.log('presenter type', this.$wrapper.type()); // 'Presenter', 'iFrame' or 'none'
 * if (this.$wrapper.type() === 'Presenter')
 * {
 * 		// we have all presenter methods...
 * 		this.$wrapper.presenter_method.();
 * }
 */
const wrapper = (() => 
{
	const inIframe = () =>
	{
		try 
		{
			return window.self !== window.top;
		} 
		catch (e) 
		{
			return true;
		}
	}

	const type = () =>
	{
		if (typeof CefSharp !== 'undefined' && typeof window?.presenter === 'object')
		{
			return 'Presenter';
		}
		else if (inIframe())
		{
			return 'iFrame';
		}

		return 'none';
	};

	return () => 
	{
		return new Promise((resolve, reject) => 
		{
			if (typeof CefSharp !== 'undefined')
			{
				CefSharp.BindObjectAsync('presenter').then((result) =>
				{
					if (result?.Success || `${result?.Message}`.includes('already bound'))
					{
						if (typeof window.presenter.type === 'undefined')
						{
							window.presenter.type = type;
						}

						return resolve(window.presenter);
					}
				}).catch((error) =>
				{
					return reject(error);
				});

				return;
			}

			return resolve({ type });
		});
	};
})();

export default {
	install: async (Vue, options = {
		initialized: (success, type) => {}
	}) =>
	{
		const instance = await wrapper();

		// create a global object
		Vue.prototype.$wrapper = instance;
		
		const success = !!instance;
		const type = success ? instance.type() : null;

		console.log('Wrapper plugin type', instance.type());

		if (typeof options?.initialized === 'function')
		{
			options.initialized(success, type);
		}
	},
};