<template>
	<v-snackbar
		v-model="notification.visible"
		:color="currentColor"
	>
		<v-icon
			class="mr-3"
			color="light"
		>
			{{ currentIcon }}
		</v-icon>
		{{ text }}
		<template #action="{attributes}">
			<v-btn
				icon
				v-bind="attributes"
				@click="notification = {visible: false}"
			>
				<v-icon
					color="light"
				>
					mdi-window-close
				</v-icon>
			</v-btn>
		</template>
	</v-snackbar>
</template>

<script>
export default
{
	name: 'Notification',
	
	props:
	[
		'visible',
		'text',
		'template',
		'color',
		'icon',
	],
	
	computed:
	{
		notification:
		{
			get ()
			{
				return this.$store.getters['notification/get'];
			},
			set ()
			{
				this.$store.commit('notification/set', arguments);
			}
		},
		currentTemplate: function ()
		{
			return this.templates.find(template => template.name === this.template) ?? this.templates[0];
		},
		currentColor: function ()
		{
			return this.color ? this.color : this.currentTemplate.color;
		},
		currentIcon: function ()
		{
			return this.icon ? this.icon : this.currentTemplate.icon;
		}
	},
	
	data: () =>
	(
		{
			templates:
			[
				{
					name: 'information',
					color: 'info',
					icon: 'mdi-information-outline'
				},
				{
					name: 'warning',
					color: 'warning',
					icon: 'mdi-alert'
				},
				{
					name: 'error',
					color: 'error',
					icon: 'mdi-alert-octagon'
				},
				{
					name: 'success',
					color: 'success',
					icon: 'mdi-check'
				}
			]
		}
	)
}
</script>