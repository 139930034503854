import VuexModuleGenerator from './../../libraries/vuex_module_generator';
import LocalStorage from '@/libraries/global/localstorage.js';

const localStorageKey = 'cart';

export default VuexModuleGenerator.generate(
{
	namespaced: true,
	state:
	{
		// the key where the order is saved in localStorage
		localStorageKey: localStorageKey,
		
		// the current order
		items: new Set(LocalStorage.get(localStorageKey) ?? [])
	},
	mutations:
	{
		// add an orderable to the current order
		add: function (state, id)
		{
			state.items.add(id);
			
			// manually trigger observer update because vuex does not support type Set
			state.__ob__.dep.notify();
			
			LocalStorage.set(state.localStorageKey, [...state.items]);
		},
		
		// remove an orderable from the current order
		remove: function (state, id)
		{
			state.items.delete(id);
			
			// manually trigger observer update because vuex does not support type Set
			state.__ob__.dep.notify();
			
			LocalStorage.set(state.localStorageKey, [...state.items]);
		},
		
		// reset the current order
		clear: function (state)
		{
			state.items = new Set();
			
			// manually trigger observer update because vuex does not support type Set
			state.__ob__.dep.notify();
			
			LocalStorage.set(state.localStorageKey, [...state.items]);
		}
	}
});