/**
 * @file Collection of helper functions that have a general use
 * @author Josua Todebusch, Nick Glöckner
 * @version 8.0.0
 */

const Utility = (function ()
{
	const colorPrototype =
	{
		/**
		 * @description returns a string representation of the color
		 * 
		 * @param {boolean=} includeAlpha if the alpha value should be included in the return string
		 * @param {string=} format that format the return string should have
		 * 
		 * @return {object} string representation of the color
		 */
		stringify: function (includeAlpha = true, format = 'rgb')
		{
			const colorValues = [this.r, this.g, this.b, this.a];
			
			if (!includeAlpha)
			{
				colorValues.pop();
			}
			
			// hex format
			if (format === 'hex')
			{
				return `#${
					[this.r, this.g, this.b].map((value) =>
					{
						return new Number(value).toString(16);
					}).join('')
				}${includeAlpha ? new Number(Math.floor(this.a * 255)).toString(16) : ''}`;
			}
			
			// standard format is rgb
			return `rgb${colorValues.length === 4 ? 'a' : ''}(${colorValues.join(',')})`; 
		},
		/**
		 * @description alters the color values of a hex string / rgb object
		 * 
		 * @param {number} multiplier the multiplier (positive: brighter, negative: darker)
		 * @param {boolean=} relativeCalculation if every value should be increased relatively to itself
		 * 
		 * @return {object} modified color object for chaining
		 */
		intensify: function (multiplier, relativeCalculation = false)
		{
			for (let [key, value] of Object.entries(this))
			{
				if (key === 'a')
				{
					continue;
				}
				
				value = parseInt(value);
				
				const baseValue = relativeCalculation ? value : 255;
				
				this[key] = self.number.clamp(value + Math.round(baseValue * multiplier), 0, 255);
			}
			
			return this;
		}
	};
	
	const consoleSave = Object.assign({}, window.console);
	
	const self =
	{
		// functions that deal with primitive types
		primitive:
		{
			/**
			 * @description Builds a url parameter format string out of the given values
			 * 
			 * @param {value} value Value
			 * @param {string[]} keys Array of string keys defining the location of the value (nestings), each value representing a level
			 * 
			 * @returns {string}
			 */
			toUrlParameterString: function (value, keys)
			{
				const baseKey = keys.shift();
				const otherKeys = keys.length ? '[' + keys.join('][') + ']' : '';
				
				if ([null, undefined, NaN].includes(value))
				{
					return '';
				}
				
				return [baseKey + otherKeys, encodeURIComponent(value)].join('=');
			}
		},
		
		// functions that deal with number types
		number:
		{
			/**
			 * @description Returns a random number (beware of the limit of JS numbers only having a precision of 16 digits: decimals parameter will only work for the rest of the precision (if 8 digits are in front of the decimal point, a maximum of 8 digits will be after that))
			 * 
			 * @param {number} minimum minimum value (inclusive)
			 * @param {number} maximum maximum value (not inclusive)
			 * @param {number=} decimals is determined automatically if -1
			 * @param {boolean=} includeMaximum if the maximum value can be a result
			 * 
			 * @returns {number}
			 */
			random: function (minimum, maximum, decimals = 16, includeMaximum = false)
			{
				if ([...arguments].slice(0, 3).some(value => typeof value !== 'number'))
				{
					return NaN;
				}
				
				function randomIntegerExclusiveMaximum (minimum, maximum)
				{
					minimum = Math.ceil(minimum);
					maximum = Math.floor(maximum);
					
					return Math.floor(Math.random() * (maximum - minimum)) + minimum;
				}
				
				function randomIntegerInclusiveMaximum (minimum, maximum)
				{
					minimum = Math.ceil(minimum);
					maximum = Math.floor(maximum);
					
					return Math.floor(Math.random() * (maximum - minimum + 1)) + minimum;
				}
				
				decimals = self.number.clamp(decimals, 0, 16);
				
				minimum = minimum * Math.pow(10, decimals);
				maximum = maximum * Math.pow(10, decimals);
				
				let number = includeMaximum ? randomIntegerInclusiveMaximum(minimum, maximum) : randomIntegerExclusiveMaximum(minimum, maximum);
				
				number = number / Math.pow(10, decimals);
				
				return number;
			},
			
			/**
			 * @description Sets a number to the given minimum if it is smaller and to the given maximum if it is higher
			 * 
			 * @see src: https://stackoverflow.com/questions/5842747/how-can-i-use-javascript-to-limit-a-number-between-a-min-max-value
			 * 
			 * @param {number} value
			 * @param {number} minimum
			 * @param {number} maximum
			 * 
			 * @returns {number}
			 */
			clamp: function (value, minimum, maximum)
			{
				if ([...arguments].some(value => typeof value !== 'number'))
				{
					return NaN;
				}
				
				return value > maximum ? maximum : value < minimum ? minimum : value;
			},
			
			/**
			 * @description Linearly interpolates between two values (build after Unity Mathf.LerpUnclamped)
			 * 
			 * @see https://docs.unity3d.com/ScriptReference/Mathf.LerpUnclamped.html
			 * 
			 * @param {number} minimum
			 * @param {number} maximum
			 * @param {number} interpolation
			 * 
			 * @returns {number}
			 */
			interpolate: function (minimum, maximum, interpolation)
			{
				if ([...arguments].some(value => typeof value !== 'number'))
				{
					return NaN;
				}
				
				return (Math.abs(minimum - maximum)) * interpolation + (minimum > maximum ? maximum : minimum);
			}
		},
		
		// functions that deal with string types
		string:
		{
			/**
			 * @description Generates a random string with a certain length based on given pool of characters
			 * 
			 * @param {number} length
			 * @param {string=} keyspace
			 * 
			 * @returns {string}
			 */
			random: function (length, keyspace = '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ')
			{
				if (typeof length !== 'number' ||
					typeof keyspace !== 'string')
				{
					return false;
				}
				
				const characters = [];
				
				for (let index = 0; index < length; index++)
				{
					characters.push(keyspace.charAt(Math.floor(Math.random() * keyspace.length)));
				}
				
				return characters.join('');
			},
			
			/**
			 * @description Capitalizes the first letter of a string
			 * 
			 * @see src: https://stackoverflow.com/questions/1026069/how-do-i-make-the-first-letter-of-a-string-uppercase-in-javascript
			 * 
			 * @param {string} string
			 * 
			 * @returns {string}
			 */
			capitalize: function (string)
			{
				if (typeof string !== 'string')
				{
					return false;
				}
				
				return string.charAt(0).toUpperCase() + string.slice(1);
			},
			
			/**
			 * @description Reverses a string
			 * 
			 * @param {string} string
			 * 
			 * @returns {string}
			 */
			reverse: function (string)
			{
				return [...string].reverse().join('');
			},
			
			/**
			 * @description Replaces all strings in a string
			 * 
			 * @param {string} string
			 * @param {object} map Map of strings to replace (every key is replaced with its associated value)
			 * 
			 * @returns {string}
			 */
			replaceAllStrings: function (string, map)
			{
				return string.replace(
					new RegExp(Object.keys(map).join('|'), 'g'),
					match => map[match]
				);
			},
			
			/**
			 * @description Removes a prefix off of a string, either by checking for a complete string at the start or by splitting the string at the first occurrence
			 * 
			 * @param {string} string
			 * @param {string} prefix
			 * @param {boolean=} byFirstDelimiter
			 * 
			 * @returns {string}
			 */
			removePrefix: function (string, prefix, byFirstDelimiter = false)
			{
				const index = string.indexOf(prefix);
				
				// prefix is found in string and is interpreted as delimiter
				if (~index && byFirstDelimiter)
				{
					return string.substring(index + prefix.length);
				}
				
				// string starts with prefix: remove it
				if (index === 0)
				{
					return string.substring(prefix.length);
				}
				
				return string;
			},
			
			/**
			 * @description Removes a suffix off of a string, either by checking for a complete string at the end or by splitting the string at the last occurrence
			 * 
			 * @param {string} string
			 * @param {string} suffix
			 * @param {boolean=} byFirstDelimiter
			 * 
			 * @returns {string}
			 */
			removeSuffix: function (string, suffix, byFirstDelimiter = false)
			{
				const index = string.lastIndexOf(suffix);
				
				// suffix is found in string and is interpreted as delimiter
				if (~index && byFirstDelimiter)
				{
					return string.substring(0, index);
				}
				
				// string ends with suffix: remove it
				if (index === string.length - suffix.length)
				{
					return string.substring(0, index);
				}
				
				return string;
			},
			
			// functions that deal with string types but expects url format
			url:
			{
				/**
				 * @description Converts a url search string into an object
				 * 
				 * @param {string} search input string
				 * @param {boolean=} parseNumbers if the method should try to parse strings to numbers
				 * @param {object=} primitives object of primitive values that are parsed (e.g. string 'true' to boolean true)
				 * 
				 * @returns {object}
				 */
				parametersToObject: function (search, parseNumbers = true, primitives = null)
				{
					primitives = primitives ||
					{
						'true': true,
						'false': false,
						'null': null,
						'undefined': undefined,
						'NaN': NaN
					};
					
					const parameterIndex = search.indexOf('?')
					
					if (parameterIndex > -1)
					{
						search = search.substring(parameterIndex + 1);
					}
					
					const setValue = function (keys, value, base)
					{
						const key = decodeURIComponent(keys.shift());
						
						if (keys.length > 0)
						{
							base[key] = {};
							
							setValue(keys, value, base[key]);
							
							return;
						}
						
						value = decodeURIComponent(value);
						
						if (primitives.hasOwnProperty(value))
						{
							value = primitives[value];
						}
						else if (parseNumbers)
						{
							const number = new Number(value).valueOf();
							
							value = Number.isNaN(number) ? value : number;
						}
						
						base[key] = value;
					};
					
					const parameters = {};
					
					search.split('&').forEach(
						(parameter) =>
						{
							if (parameter.length === 0)
							{
								return;
							}
							
							let [key, value] = parameter.split('=');
							
							// just parameter without value
							if (value === undefined)
							{
								value = true;
							}
							
							let keys = [key];
							
							if (key.includes(']['))
							{
								keys = key.replaceAll(']', '').split('[');
							}
							
							setValue(keys, value, parameters);
						}
					);
					
					return parameters;
				},
				
				/**
				 * @description Calculates a url path
				 * 
				 * @param {string} url url to calculate
				 * @param {boolean=} trailing if a trailing glue character should be added
				 * @param {string=} glue the character that is used as a slash
				 * 
				 * @returns {string}
				 */
				calculate: function (url, trailing = null, glue = '/')
				{
					let protocol = ''
					let string = url;
					
					const match = url.match(/(:(\/|\\){0,3})/);
					
					if (match)
					{
						protocol = url.slice(0, match.index + match[0].length);
						
						protocol = protocol.replace(/\/|\\/g, glue);
						
						string = url.slice(match.index + match[0].length);
					}
					
					const parts = string.split(/\/|\\/g).filter(item => item);
					
					const calculated = [];
					
					for (const part of parts)
					{
						if (part === '.')
						{
							continue;
						}
						else if (part === '..')
						{
							calculated.pop();
						}
						else
						{
							calculated.push(part);
						}
					}
					
					return protocol + calculated.join(glue) + (trailing ? glue : '');
				},
				
				/**
				 * @description Returns the file name from an url (e.g. 'index' of 'localhost/abc/index.html')
				 * 
				 * @param {string=} url
				 * @param {boolean=} extension if the extension should be included in
				 * 
				 * @returns {string}
				 */
				fileName: function (url = window.location.pathname, extension = false)
				{
					let fileName = url.split('/').pop();
					
					if (!extension)
					{
						fileName = fileName.split('.').slice(0, -1).join('.');
					}
					
					return decodeURI(fileName);
				}
			}
		},
		
		// functions that deal with array types
		array:
		{
			/**
			 * @description Generate an array with a linear series of numbers in it
			 * 
			 * @param {number} elements The count of elements that the array should have
			 * @param {number=} start The starting point of the numerical series
			 * @param {number=} step The amount to increase the series per number with
			 * 
			 * @return {array}
			 */
			linear: function (elements, start = 0, step = 1)
			{
				const array = [];
				
				for (let index = 0; index < elements; index++)
				{
					array.push(start + index * step);
				}
				
				return array;
			}
		},
		
		// functions that deal with object types
		object:
		{
			/**
			 * @description Filters all fields of an object by checking the predicate. Works like Array.prototype.filter but for objects.
			 * 
			 * @param {object} object The object to filter
			 * @param {function} predicate The callback to check if the field should be filtered
			 * 
			 * @return {object}
			 */
			filter: function (object, predicate)
			{
				const filtered = {};
				
				for (const [key, value] of Object.entries(object))
				{
					if (predicate(value, key))
					{
						filtered[key] = value;
					}
				}
				
				return filtered;
			},
			
			/**
			 * @description Gets a nested value from an object/array by resolving all fields one after another and returns the value
			 * 
			 * @param {object|array} object The object/array to get a value from
			 * @param {string[]} fields The fields to resolve
			 * @param {boolean=} getUndefinedFieldIndex If the value to resolve does not exist and this value is set to true, the function will return the index of the first field in 'fields' which was undefined
			 * 
			 * @return {mixed} The value of the nested field inside the object/array
			 */
			resolveValue: function (object, fields, getUndefinedFieldIndex = false)
			{
				let undefinedFieldIndex = null;
				
				const field = fields.reduce(
					(accumulator, currentValue, currentIndex) =>
					{
						if (undefinedFieldIndex === null && accumulator[currentValue] === undefined)
						{
							undefinedFieldIndex = currentIndex;
						}
						
						return accumulator?.[currentValue]
					},
					object
				);
				
				if (getUndefinedFieldIndex)
				{
					return [field, undefinedFieldIndex];
				}
				
				return field;
			},
			
			/**
			 * @description Sets a nested value into an object/array by resolving all fields one after another (if a field does not exist, an empty object is created and the operation continues)
			 * 
			 * @param {object|array} object The object/array to set a nested value into
			 * @param {string[]} fields The fields to resolve
			 * @param {mixed} value The value to be set
			 * @param {boolean=} createArrayForIntegers If this value is set to true, instead of creating an empty object if a field does not exist, an array is created
			 * 
			 * @return {mixed} Returns the first field index that did not exist
			 */
			integrateValue: function (object, fields, value, createArrayForIntegers = false)
			{
				let undefinedFieldIndex = null;
				
				fields.reduce(
					(accumulator, currentValue, currentIndex) =>
					{
						// at last field: set value
						if (currentIndex + 1 == fields.length)
						{
							accumulator[currentValue] = value;
							
							return accumulator[currentValue];
						}
						// if field does not exist: create it
						//else if (accumulator[currentValue] === undefined)
						//{
							// for first undefined field: set
							if (accumulator[currentValue] === undefined && undefinedFieldIndex === null)
							{
								undefinedFieldIndex = currentIndex;
							}
							
							// Array if option is set and field is integer, else object
							if (Number.isInteger(currentValue) && createArrayForIntegers)
							{
								accumulator[currentValue] = [];
							}
							else
							{
								accumulator[currentValue] = {};
							}
						//}
						
						return accumulator[currentValue];
					},
					object
				);
				
				return undefinedFieldIndex;
			},
			
			/**
			 * @description Removes a nested value from an object/array by resolving all fields on after another
			 * 
			 * @param {object|array} object The object/array to remove a nested value from
			 * @param {string[]} fields The fields to resolve
			 * @param {boolean=} removeEmptyPath If this value is set to true, the function also removes all objects that have no value left after the target value has been erased
			 * 
			 * @return {mixed} Returns the first field index that did not exist
			 */
			removeValue: function (object, fields, removeEmptyPath = false)
			{
				const originalFieldsLength = fields.length;
				
				// save the value to be removed so that we can return it later
				let fieldValue = null;
				
				for (let index = 0; index < originalFieldsLength; index++)
				{
					const currentField = fields.pop();
					
					const [value, undefinedFieldIndex] = self.object.resolveValue(object, fields, true);
					
					if (index === 0)
					{
						fieldValue = value[currentField];
					}
					
					if (index === 0 ||
						(value instanceof Array && value[currentField].length <= 1) ||
						(typeof value === 'object' && value !== null && Object.keys(value[currentField]).length <= 1)
					)
					{
						delete value[currentField];
					}
					
					if (!removeEmptyPath)
					{
						return fieldValue;
					}
				}
				
				return fieldValue;
			},
			
			/**
			 * @description Recursively maps an object
			 * 
			 * @param {object} object The variable to check
			 * @param {function} callback The callback function that is used on every "leaf" (takes 3 parameters: 1. field value, 2. field key, 3. stack of keys where the field is (nested))
			 * @param {function=} condition The method that provides additional checks for if the current value should be recursed or not (takes 2 parameters: 1. field value, 2. field key)
			 * @param {function=} keyPath The stack of keys where the current field is (is used internally)
			 * 
			 * @return {boolean}
			 */
			mapRecursive: function (object, callback, condition = (value, key) => { return !self.other.anyInstanceOf(value, [Blob, File])}, keyPath = [])
			{
				let processed = object instanceof Array ? [] : {};
				
				for (const [key, value] of Object.entries(object))
				{
					if
					(
						typeof value === 'object' &&
						value !== null &&
						condition(value, key)
					)
					{
						processed[key] = self.object.mapRecursive(value, callback, condition, [...keyPath, key]);
						
						continue;
					}
					
					processed[key] = callback(value, [...keyPath, key]);
				}
				
				return processed;
			},
			
			/**
			 * @description Flattens object to 1 depth using all keys as string joined key
			 * 
			 * @param {object} object The object to flatten
			 * @param {string=} prefix Key prefix
			 * @param {string=} interfix Interfix for joining keys
			 * 
			 * @return {object}
			 */
			collapse: function (object, interfix = '.', prefix = null)
			{
				const flat = {};
				
				self.object.mapRecursive(
					object,
					function (value, keys)
					{
						flat[keys.join(interfix)] = value;
						
						return value;
					},
					function (value, key)
					{
						return !self.other.anyInstanceOf(value, [Blob, File]);
					},
					[prefix].filter((value) => ![null, undefined, NaN, false].includes(value))
				);
				
				return flat;
			},
			
			/**
			 * @description Reverse operation of object.collapse: instead of flattening an object recursively, builds a nested object based on a flat object by splitting the keys with the parameter interfix
			 * 
			 * @param {object} object The flat object to construct from
			 * @param {string=} interfix Interfix for splitting keys
			 * 
			 * @return {object}
			 */
			construct: function (object, interfix = '.')
			{
				const deep = {};
				
				for (const [index, value] of Object.entries(object))
				{
					self.object.integrateValue(
						deep,
						index.split(interfix),
						value
					);
				}
				
				return deep;
			},
			
			/**
			 * @description Flattens object with same functionality of Array.prototype.flat, but for object
			 * 
			 * @param {object} object The object to flatten
			 * @param {integer} depth The depth how deep the nested object should be flattened
			 * 
			 * @return {object}
			 */
			flat: function (object, depth = 0)
			{
				const flat = {};
				
				self.object.mapRecursive(
					object,
					function (value, keys)
					{
						if (depth > 0 && keys.length >= depth)
						{
							self.object.integrateValue(
								flat,
								keys.slice(0, depth),
								value
							);
							
							return value;
						}
						
						flat[keys.pop()] = value;
						
						return value;
					},
					function (value, key)
					{
						return !self.other.anyInstanceOf(value, [Blob, File]);
					}
				);
				
				return flat;
			},
			
			/**
			 * @description Merges two objects together
			 * 
			 * @param {object} mixed The first object to merge
			 * @param {object} object The second object to merge
			 * @param {boolean=} sameToArray If a new array with both values should be created when both objects have the same property
			 * @param {boolean=} mergeSameArrays If when both objects have an array at the same key they should be merged into one array
			 * 
			 * @return {object}
			 */
			merge: function (base, object, sameToArray = false, mergeSameArrays = true)
			{
				const merged = {};
				
				for (const [property, value] of [...Object.entries(base), ...Object.entries(object)])
				{
					if (typeof value === 'object' && value !== null && !Array.isArray(value))
					{
						merged[property] = this.merge(base[property] || {}, value);
						
						continue;
					}
					
					if (sameToArray && merged[property] !== undefined)
					{
						if (Array.isArray(merged[property]) && Array.isArray(value) && mergeSameArrays)
						{
							merged[property] = [...merged[property], ...value];
						}
						else
						{
							merged[property] = [merged[property], value];
						}
					}
					else
					{
						merged[property] = value;
					}
				}
				
				return merged;
			},
			
			/**
			 * @description Converts an object into a FormData object
			 * 
			 * @param {object} object The object to convert
			 * 
			 * @return {FormData}
			 */
			toFormData: function (object)
			{
				const formData = new FormData();
				
				self.object.mapRecursive(
					object,
					function (value, keys)
					{
						const baseKey = keys.shift();
						const otherKeys = keys.length ? '[' + keys.join('][') + ']' : '';
						
						if ([null, undefined, NaN].includes(value))
						{
							return;
						}
						
						formData.set(baseKey + otherKeys, value);
					}
				);
				
				return formData;
			},
			
			/**
			 * @description Converts an object into a url parameter string
			 * 
			 * @param {object} object The object to convert
			 * 
			 * @return {string}
			 */
			toUrlParameterString: function (object)
			{
				return Object.values(
					self.object.collapse(
						self.object.mapRecursive(
							object,
							self.primitive.toUrlParameterString
						)
					)
				).join('&');
			}
		},
		
		// functions that deal with function types
		function:
		{
			/**
			 * @description Invokes the given function with either a given context or this and its arguements by using apply and the arguments object. After invoking the function it will set the given function to null to prevent it from being run again.
			 * 
			 * @param {function} method
			 * @param {object=} context context that the function should be executed in
			 * 
			 * @returns {boolean} true if function got invoked, false if not
			 */
			invokeOnce: function (method, context = this) 
			{ 
				let result = false;
				
				return function ()
				{
					if (method && typeof method === 'function') 
					{
						method.apply(context, arguments);
						
						result = true;
						
						method = null;
					}
					
					return result;
				};
			},
			
			/**
			 * @description Returns a function, that, as long as it continues to be invoked, will not be triggered. The function will be called after it stops being called for N milliseconds. If immediate is passed, trigger the function on the leading edge, instead of the trailing.
			 * 
			 * @param {function} method The function that will be invoked when debounce is stopped being invoked within the set amount of time.
			 * @param {number} wait Time in milliseconds method will be invoked after debounce has stopped being triggered.
			 * @param {boolean=} immediate
			 * 
			 * @returns {boolean} Returns false if @param method is not a function otherwise returns @param method
			 */
			debounce: function (method, wait, immediate = false) 
			{
				if (typeof method !== 'function')
				{
					return false;
				}
				
				let timeout;
				
				return function () 
				{
					clearTimeout(timeout);
					
					timeout = setTimeout(
						() =>
						{
							timeout = null;
							
							if (!immediate)
							{
								method.apply(this, arguments);
							}
						},
						wait
					);
					
					if (immediate && !timeout)
					{
						method.apply(this, arguments);
					}
				};
			},
			
			/**
			 * @description setInterval wrapper that can immediately call a function and then does a standard setInterval call
			 * 
			 * @param {boolean} immediate also calls the function immediately
			 * 
			 * @returns {int} interval id
			 */
			setInterval: function (immediate, ...parameters)
			{
				if (typeof parameters[0] !== 'function')
				{
					return false;
				}
				
				if (immediate)
				{
					parameters[0](parameters.slice(2));
				}
				
				return setInterval(...parameters);
			}
		},
		
		color:
		{
			/**
			 * @description creates a color object and returns it
			 * 
			 * @param {string} color the color as string representation (rgb/rgba/hex)
			 * 
			 * @returns {object} color object
			 */
			create: function (color)
			{
				const parsed = Object.create(colorPrototype);
				
				if (typeof color === 'string')
				{
					color = color.trim();
					
					if (color.startsWith('rgb'))
					{
						color = color.slice(0, color.length - 1).split(/\((.+)/)[1].split(',').map((piece) =>
						{
							return piece.trim();
						});
						
						return Object.assign(parsed,
						{
							r: parseInt(color[0]),
							g: parseInt(color[1]),
							b: parseInt(color[2]),
							a: parseFloat(color[3] || 1),
						});
					}
					
					if (color.startsWith('#'))
					{
						color = color.slice(1);
						
						// formats ABC, ABCD, AABBCC, AABBCCDD
						if (![3, 4, 6, 8].includes(color.length))
						{
							return null;
						}
						
						// if format is ABC or ABCD, double each char
						if ([3, 4].includes(color.length))
						{
							color = color.split('').map((char) => 
							{
								return char + '0';
							}).join('');
						}
						
						let alpha = 1;
						
						// given alpha in hex
						if (color.length === 8)
						{
							alpha = parseInt(color.slice(6, 8), 16) / 255;
						}
						
						return Object.assign(parsed,
						{
							r: parseInt(color.slice(0, 2), 16),
							g: parseInt(color.slice(2, 4), 16),
							b: parseInt(color.slice(4, 6), 16),
							a: alpha
						});
					}
				}
				else if ([3, 4].includes(arguments.length))
				{
					const values = [...arguments];
					
					if (values.length === 3)
					{
						values.push(1);
					}
					
					if (!values.every(value => typeof value === 'number'))
					{
						return null;
					}
					
					return Object.assign(parsed,
					{
						r: Math.round(self.number.clamp(values[0], 0, 255)),
						g: Math.round(self.number.clamp(values[1], 0, 255)),
						b: Math.round(self.number.clamp(values[2], 0, 255)),
						a: self.number.clamp(values[3], 0, 1),
					});
				}
				
				return null;
			},
			
			/**
			 * @description Linearly interpolates between two colors (build after Unity Mathf.LerpUnclamped)
			 * 
			 * @see https://docs.unity3d.com/ScriptReference/Mathf.LerpUnclamped.html
			 * 
			 * @param {number} minimum
			 * @param {number} maximum
			 * @param {number} interpolation
			 * 
			 * @returns {number}
			 */
			interpolate: function (minimum, maximum, interpolation)
			{
				const values = [];
				
				for (const key of Object.keys(minimum))
				{
					values.push(minimum[key] + (maximum[key] - minimum[key]) * interpolation);
				}
				
				return this.create(...values);
			}
		},
		
		// functions that dont deal with any types above and have custom purpose
		other:
		{
			/**
			 * @description Checks if a value is any of the given types. (Only for cleaner code when checking multiple types, no fix for the infamous typeof null === 'object')
			 * 
			 * @param {mixed} object The variable to check
			 * @param {string[]} types The array of types that should be checked
			 * 
			 * @return {boolean}
			 */
			anyTypeOf: function (value, types)
			{
				for (const type of types)
				{
					if (typeof value === type)
					{
						return true;
					}
				}
				
				return false;
			},
			
			/**
			 * @description Checks if a value is any of the given instances
			 * 
			 * @param {mixed} value The variable to check
			 * @param {type[]} types The array of types that should be checked
			 * 
			 * @return {boolean}
			 */
			anyInstanceOf: function (value, types)
			{
				for (const type of types)
				{
					if (value instanceof type)
					{
						return true;
					}
				}
				
				return false;
			},
			
			/**
			 * @description Returns a string for a default source for a html img tag. Shows one transparent pixel. 
			 * 				Use this to suppress the html img 'src-not-found' standin image.
			 * 
			 * @returns {string}
			 */
			dummyImageSource: function ()
			{
				return 'data:image/gif;base64,R0lGODlhAQABAAAAACwAAAAAAQABAAA=';
			},
			
			/**
			 * @description Enable the global console function by tool
			 * 
			 * @param {string} tool Which Method from console should be targeted
			 * 
			 * @return {boolean} Returns true if the function was enabled
			 */
			enableConsole: function (tool)
			{
				if (typeof tool === 'string' && 
					consoleSave[tool] && 
					window.console[tool] !== consoleSave[tool])
				{
					window.console[tool] = consoleSave[tool];
					
					return true;
				}
				
				return false;
			},
			
			/**
			 * @description Disable the global console function by tool
			 * 
			 * @param {string} tool Which Method from console should be targeted
			 * 
			 * @return {boolean} Returns true if the function was disabled
			 */
			disableConsole: function (tool)
			{
				if (typeof tool === 'string' && 
					window.console[tool] && 
					window.console[tool] === consoleSave[tool])
				{
					window.console[tool] = () => {};
					
					return true;
				}
				
				return false;
			}
		}
	};
	
	return self;
})();

if (window)
{
	window.Utility = Utility;
}

export default Utility;