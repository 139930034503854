<template>
	<v-btn
		:color="color"
		class="white--text"
		@click="$emit('click', $event)"
	>
		<template #default>
			<slot name="default"/>
		</template>
	</v-btn>
</template>

<script>
export default
{
	name: 'GbButton',
	
	props:
	{
		color:
		{
			type: String,
			default: 'primary'
		}
	},
	
	created ()
	{
		
	},
	
	computed:
	{
		
	},
	
	data: () =>
	(
		{
			
		}
	)
};
</script>

<style lang="scss" scoped>
.v-btn
{
	border-radius: 1000px;
	text-transform: unset;
}
</style>