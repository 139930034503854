import Vue from 'vue';
import App from './App.vue';
import store from './store';
import router from './router';
import i18n from './i18n';
import vuetify from './plugins/vuetify';
import globals from './plugins/globals';
import wrapper from './plugins/wrapper';

Vue.config.productionTip = false;

Vue.use(globals);

Vue.use(
	wrapper,
	{
		initialized: (success, type) =>
		{
			new Vue(
				{
					vuetify,
					store,
					router,
					i18n,
					render: h => h(App)
				}
			).$mount('#app');
		}
	}
);